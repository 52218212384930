import styled from "styled-components"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 2.5rem 1rem;

  @media (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }
  grid-gap: 16px;
`

export default Wrapper
