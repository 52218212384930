import React from "react"
import { graphql } from "gatsby"

// Components
import { Layout } from "../components/layout"
import { PaginationButton, PaginationWrapper } from "../components/pagination"
import { Wrapper, LetterCard } from "../components/letter"

const Letters = ({ data, pageContext }) => {
  const doc = data.allSanityLetter.edges
  if (!doc) return null

  const letterList = doc.map(letter => {
    return <LetterCard input={letter} />
  })

  // Letter page navigation
  const { currentPage, numLetterPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numLetterPages
  const prevPage = currentPage - 1 === 1 ? `/` : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  console.log(doc.node)

  return (
    <Layout>
      <Wrapper>{letterList}</Wrapper>
      <PaginationWrapper>
        {!isFirst && (
          <PaginationButton to={`/letters${prevPage}`} rel="prev">
            ← Previous Page
          </PaginationButton>
        )}
        {!isLast && (
          <PaginationButton to={`/letters${nextPage}`} rel="next">
            Next Page →
          </PaginationButton>
        )}
      </PaginationWrapper>
    </Layout>
  )
}

export const query = graphql`
  query LettersQuery($skip: Int!, $limit: Int!) {
    allSanityLetter(limit: $limit, skip: $skip) {
      edges {
        node {
          title
          _rawBody(resolveReferences: { maxDepth: 5 })
          athlete {
            name
          }
          slug {
            current
          }
        }
      }
    }
  }
`

export default Letters
