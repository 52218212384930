import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// Components
// import { PortableText } from "../content"

const Wrapper = styled(Link)`
  background: ${props => props.theme.colors.light};
  padding: 2rem 4rem;
  text-decoration: none;
  color: ${props => props.theme.colors.dark};
  transition: 0.1s all ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
`

function excerpt(text) {
  const length = text.length
  const excerptLength = 150

  if (length === 1) {
    return text[0].children[0].text.substring(0, excerptLength) + "..."
  } else if (length > 1) {
    return text[1].children[0].text.substring(0, excerptLength) + "..."
  }
}

const LetterCard = ({ input }) => {
  return (
    <Wrapper to={`/letter/${input.node.slug.current}`}>
      <h3>{input.node.title || `A letter to Laurel`}</h3>
      {input.node.athlete.name && (
        <p>
          <em>From: {input.node.athlete.name}</em>
        </p>
      )}
      {excerpt(input.node._rawBody) && <p>{excerpt(input.node._rawBody)}</p>}
    </Wrapper>
  )
}

export default LetterCard
